html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;

}

.body-wrapper-bg {
  position: relative;
  z-index: 2;
}

.body-wrapper-bg::before {
  position: absolute;
  content: "";
  background-image: url(../image/bg-grid-lines.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 10000px;
  z-index: -1;
}

.body-wrapper {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 275;
  font-size: 72px;
  line-height: 113%;
  padding-bottom: 40px;
  padding-top: 50px;
}

h1 span {
  display: block;
  font-weight: 700;
}

h2 {
  font-family: 'Kanit';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 113.5%;
color: #000000;
}

h3 {
  font-family: 'Kanit';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 113.5%;
color: #000000;
padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 62px;
  margin-bottom: 25px;
}



@media only screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-size: 17px;
  line-height: 30px;
}

p b {
  font-weight: 800;
}

p:not(:last-of-type) {
  padding-bottom: 25px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .banner-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  background-color: transparent;
  padding: 0;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .banner-title{
      font-size: 22px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
      text-align: left!important;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .banner-title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center!important;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}


.site-logo {
  font-weight: 400;
  font-size: 18px;
  color: #040603;
}

.site-logo span {
  font-weight: 700;
}

.logo-wrapper {
  padding-top: 20px;
  padding-bottom: 22px;
}

.site-header-inner {
  display: grid;
    grid-template-columns: 1fr 36%;
    position: relative;
}

.site-header-inner::after {
  position: absolute;
  content: "";
  width: 77px;
  height: 77px;
  background-color: #14FF00;
  border-radius: 50%;
  right: -247px;
    top: 156px;
    z-index: -4;
}

.site-header .container.bnr::before,
.site-header .container.bnr::after {

  content: none;
}

.site-header .container {
  position: relative;

}
.site-header .container::before {
  position: absolute;
  content: "";
  width: 316px;
  height: 316px;
  background-color: #267DFF;
  border-radius: 50%;
  top: -100px;
  left: -400px;
}

.site-header-img {
  position: relative;
}

.site-header-img::after {
  position: absolute;
  content: "";
  background-image: url(../image/three-x.svg);
  width: 38px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
}

.site-header-img::before {
  position: absolute;
  content: "";
  background-image: url(../image/hero-wiggle.svg);
  width: 740px;
  height: 740px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 90px;
  top: -83px;
  z-index: -2;
}

nav {
  display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding-top: 60px;
    width: 550px;
    position: relative;
}

nav::before {
  position: absolute;
  content: "";
  background-image: url(../image/triangle.svg);
  width: 38px;
  height: 33px;
  bottom: -10px;
  left: -271px;
}

.first-col,
.second-col {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

nav a {
  width: 250px;
  border-radius: 50px;
  border: 1.5px solid black;
  padding: 16px 30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  position: relative;
  background-color: #fff;

}

nav a::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 22px;
  background-image: url(../image/arrow-down.svg);
  right: 23px;
  top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transform: translate(0, -15px);
  transition: all 0.3s ease-in-out;
}

nav a:hover::after {
  opacity: 1;
  transform: translate(0, 0);
}

nav a:hover {
  border: 1.5px solid #14FF00;
}

.green {
  border: 1.5px solid #14ff00 !important;
}

.header-description {
  position: relative;
}

.header-description::before {
  position: absolute;
  content: "";
  background-image: url(../image/bitcoin.svg);
  width: 78px;
  height: 78px;
  background-size: contain;
  right: 0;
  bottom: -80px;
}

.header-description::after {
  position: absolute;
  content: "";
  width: 97px;
  height: 22px;
  background-color: #000;
  left: -175px;
  top: 5px;
}


.what-is {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 100px;
  gap: 80px;
  position: relative;
}

.what-is::before {
  position: absolute;
  content: "";
  background-image: url(../image/currencies.svg);
  width: 890px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 12px;
  left: 0;
}

.what-is p {
  padding-top: 50px;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.grid-col-2 p {
  padding: 40px;
  border: 1px solid #000;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
}

.grid-col-2 p::before {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #14ff00;
  top: 10px;
  left: 15px;
}

.grid-col-first p:first-of-type::before {
  position: absolute;
  content: "1.";
}

.grid-col-first p:nth-of-type(2)::before {
  position: absolute;
  content: "2.";
}

.grid-col-second p:first-of-type::before {
  position: absolute;
  content: "3.";
}

.grid-col-second p:nth-of-type(2)::before {
  position: absolute;
  content: "4.";
}

.grid-col-1 p {
  width: 85%;
}

.proof-of-work {
  /* background: rgba(254, 249, 241, 0.75); */
  padding: 85px 20px 270px;
  text-align: center;
  margin-top: 100px;
  position: relative;
}

.proof-of-work::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color:rgba(254, 249, 241, 0.75);;
  z-index: -4;
  top: 0;
  left: 0;
}

.proof-of-work p:first-of-type {
  padding-bottom: 50px;
  position: relative;
}

.proof-of-work p:first-of-type::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 6px;
  background-color: #000;
  bottom: -10px;
  left: calc(50% - 20px);
}

.proof-of-work h2:nth-of-type(2) {
  padding-top: 50px;
}

.center {
  text-align: center;
}

.crypto-wallet {
  padding: 65px 80px;
  position: relative;
}

.crypto-wallet::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: -4;
}

.crypto-wallet h4 {
  font-family: 'Kanit';
  padding-bottom: 13px;
  font-weight: 700;
}

.grid-wallets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 28px;
  padding-top: 50px;
}

.wallet {
  padding: 30px 35px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 190px;
    align-items: center;
    gap: 24px;
}

.wallet p {
  font-size: 16px;
  line-height: 26px;
}

.cta-wallets a{
  width: 190px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14FF00;
  border-radius: 56px;

  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
text-align: center;
color: #000000;
}

.cta-wallets a {
  color: #000;
}

.paperwallet {
  margin-top: 38px;
  padding: 30px 35px;
  border: 1px solid #000;
}

#crypto-wallet {
  position: relative;
  padding-left: 40px;
}

#crypto-wallet::before {
  position: absolute;
  content: "";
  background-image: url(../image/digital-wallet.svg);
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 6px;
}

.hardware-wallets {
  padding: 0 80px;
}

.hardware-wallets h3 {
  padding-left: 40px;
  position: relative;
}

.hardware-wallets h3:first-of-type::before {
  position: absolute;
  content: "";
  background-image: url(../image/chip.svg);
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 6px;
  left: 0;
}

.hardware-wallets h3:nth-of-type(2)::before {
  position: absolute;
  content: "";
  background-image: url(../image/terminal.svg);
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 6px;
  left: 0;
}

.hardware-wallets p:last-of-type {
  padding-bottom: 60px;
}

footer {
  background-color: #fef9f1;
}

footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 65px;
}

.setups {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 25px;
}


.setups a {
  padding: 15px 30px;
  border-radius: 40px;
  border: 1.5px solid #14FF00;
  width: 285px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  position: relative;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.setups a::after {
  position: absolute;
  content: "";
  background-image: url(../image/play-btn.svg);
  width: 44px;
  height: 44px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 8px;
    top: 8px; 
}

.setups a:hover {
  text-decoration: underline;
  background-color: #14FF00;

}

.types-mining {
  padding: 65px 80px;
}

.types-box {
  padding: 30px 35px;
  border: 1px solid #000;
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.95);
}

.types-box h4 {
  padding-bottom: 11px;
  font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 113.5%;
color: #000;

}

.how-to {
  display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 49px;
}

.how-to-img {
  position: relative;
}

.how-to-img::before {
  position: absolute;
  content: "";
  background-image: url(../image/blockchains.svg);
  width: 556px;
  height: 750px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.how-to-content .m-slide-down p:last-of-type {
  padding-bottom: 110px;
}

.how-to-content p:first-of-type {
  padding-bottom: 25px;
}

.how-to-content h2 {
  padding-top: 20px;
}



.steps {
  display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 65px 150px;
    padding-top: 100px;
}

.steps h3 {
  font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 113.5%;
color: #000000;
padding-bottom: 12px;
}

.step {
  margin-bottom: 50px;
  position: relative;
}

.step::before {
  content: "";
  position: absolute;
  font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 113.5%;
color: #14FF00;
top: -50px;
}

.step:first-of-type::before {
  content: "1.";
}

.step:nth-of-type(2)::before {
  content: "2.";
}

.step:nth-of-type(3)::before {
  content: "3.";
}

.step:nth-of-type(4)::before {
  content: "4.";
}

.grid-1,
.grid-2 {
  display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}

.top-three-categories {
  width: 290px;
  padding-right: 35px;
  position: relative;

  font-family: 'Lato';
font-style: normal;
font-weight: 800;
font-size: 17px;
line-height: 28px;
color: #000000;
}

.top-three-categories::after {
  position: absolute;
  content: "";
  background-image: url(../image/arrow-right.svg);
  width: 29px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 21px;
  top: 25px;
}

.navigation {
  display: flex;
  align-items: center;
}

.nav-bar {
  display: flex;
  gap: 29px;
}

.tab-button {
  padding: 13px 40px;
  border: 1.5px solid #000;
  border-radius: 50px;

  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
color: #000000;
transition: all 0.2s ease-in-out;
cursor: pointer;
}

.tab-button:hover {
  border-color: #14FF00;
}

.tab-button.active {
  background-color:#14FF00;
  border-color: #14FF00;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

li {
  list-style: none;
}

.box {
  padding: 40px 50px;
  border: 1.5px solid #000;
  margin-top: 40px;
}

.box h4 {
  font-family: 'Kanit';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 113.5%;
color: #000000;
}

.box h4 span {
  font-weight: 600;
}

.specifications {
  background-color: #267DFF;
  width: 195px;
  color: #fff;
  font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
letter-spacing: 0.12em;
text-transform: uppercase;
padding: 4px 15px;
margin-top: 10px;
}

.specs-wrapper li {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  padding-left: 28px;
  margin-top: 28px;
  position: relative;
}

.specs-wrapper li::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #14FF00;
  top: 8px;
  left: 0;
}

.new-row span {
  display: block;
}

.specs-wrapper li span {
  font-weight: 800;
}

.inline-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.info {
  padding-top: 25px;
}

.mining-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 100px;
  flex-wrap: wrap;
  gap: 100px;
}

.mining-wrapper h2 span {
  display: block;
}

.mining-wrapper h2 {
  position: relative;
  padding-bottom: 120px;
}

.mining-wrapper h2::before {
  position: absolute;
  content: "";
  background-image: url(../image/mining-arrow.svg);
  width: 345px;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -70px;
}

.mining-calculator {
  position: relative;
}

.mining-calculator::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #FEF9F1;
  top: -50px;
  z-index: -5;
}

.mining-img {
  border: 1px solid #000;
  background-color: #fff;
}

.mining-img-inner {
  background-image: url(../image/mining-calculator.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}


/* MAIN TABLE  */

.main-table {
padding-top: 100px;
padding-bottom: 80px;
}

.main-table .td {
  display: flex;
  align-items: center;
  border: 1.5px solid #000;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 15px;
}

.mobile-wrapper  {
  display: flex;
  align-items: center;
  padding-left: 25px;
}


.mcap-mobile {
  display: none;
}

.border-left {
  display: flex;
}

.logo {
  width: 185px;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FDF6ED;

  font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 113.5%;
color: #000000;

}

.mobile-wrapper p {
  padding-top: 30px;
  padding-left: 20px;
  position: relative;
}

.border-left p {
  border-left: 2px solid black;
  min-width: 129px;
}

.border-left p::before {
  top: 0;
  left: 20px;
  font-weight: 400;
  position: absolute;
}

.m-cap-desktop p,
.mcap-mobile p,
.difficulty p {
  position: relative;
}

.m-cap-desktop p::before,
.mcap-mobile p::before,
.difficulty p::before {
  top: 0;
  left: 20px;
  font-weight: 400;
  position: absolute;
}

.m-cap-desktop p::before {
  content: "Market Cap";
  font-size: 16px;
}

.difficulty p::before {
  content: "Difficulty";
}

.block-reward p::before {
  content: "Block Reward";
}

.algo p::before {
  content: "Hash type";
}

.block-time p::before {
  content: "Block Time";
}

.inner-content-table p {
  font-family: 'Lato';
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 26px;
color: #000000;
}

.m-cap-desktop,
.difficulty {
  background-color: #F5F5F5;
  margin-right: 15px;
  padding: 25px 0;
  padding-right: 25px;

}

.m-cap-desktop {
  width: 200px;
}

.difficulty {
  width: 185px;
}

.cta a { 
  width: 165px;
    height: 60px;
    border-radius: 55px;
    /* color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #14FF00;
    transition: all 0.2s ease-in-out;
  }

.cta a {
  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
color: #000000;
}

.logo.ethereum {
  background-color: #F3F6FF;
}

.logo {
  position: relative;
}


.logo::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  top: 2px;
  left: 2px;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo.bitcoin::before {
  background-image: url(../image/btc-color.svg);
}

.logo.ethereum::before {
  background-image: url(../image/eth-color.svg);
}

.logo.dogecoin::before{
  background-image: url(../image/dogecoin-color.svg);
}

.logo.litecoin::before {
  background-image: url(../image/ltc-color.svg);
}

.logo.bitcoin-cash::before {
  background-image: url(../image/bitcoin-cash-color.svg);
  width: 28px;
  height: 28px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 7px;
  left: 7px;
}

.logo.ethereum-classic::before {
  background-image: url(../image/eth-classic-color.svg);
  width: 28px;
  height: 28px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 7px;
  left: 7px;
}

.logo.monero::before {
  background-image: url(../image/monero-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.z-cash::before {
  background-image: url(../image/zcash-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.bitcoin-sv::before {
  background-image: url(../image/btc-sv-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.kadena::before {
  background-image: url(../image/kadana-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.dash::before {
  background-image: url(../image/dash-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.decred::before {
  background-image: url(../image/decred.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.horizen::before {
  background-image: url(../image/horizen-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.siacoin::before {
  background-image: url(../image/siacoin.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.logo.ravencoin::before {
  background-image: url(../image/ravencoin-color.svg);
  width: 28px;
  height: 28px;
  top: 7px;
  left: 7px;
}

.intro .td .cta a {
  transition: all 0.2 ease-in-out;
}

.intro .td:hover .cta a {
  background-color: #000;
  color: #fff;
}

.m-slide-down {
  height: 0;
  overflow: hidden;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
}
.read-more-1>*{
  pointer-events: none;
}
.read-more-1.close,  .collapse-1.close{
display: none;
}

.m-readmore-btn-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.read-more-1 {
  padding: 15px 150px 16px 20px;
  border-radius: 50px;
  border: 1.5px solid #000;

  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
color: #000000;
position: relative;
cursor: pointer;
background-color: #fff;
z-index: 2;
}

.read-more-1::after {
  position: absolute;
  content: "";
  background-image: url(../image/load-more.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  right: 5px;
  top: 5px;
}

.m-readmore-btn-wrapper::before {
  position: absolute;
  content: "";
  width: 355px;
  height: 7px;
  background-color: #14FF00;
  z-index: -2;
  top: 28px;
}

.trusted-places h2 {
  text-align: center;
}

.trusted-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px;
  width: 80%;
  margin: 0 auto;
}

.grid-box {
  text-align: center;
  border: 1.5px solid black;
  height: 204px;
  width: 286px;
  padding-top: 30px;
}

.grid-box h3 {
  font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 113.5%;
text-align: center;
color: #000000;
padding-bottom: 10px;
}

.suppliers {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.suppliers a {
  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
color: #000000;
border: 1.5px solid #14FF00;
border-radius: 50px;
padding: 1px 23px;
transition: all 0.2s ease-in-out;
}

.suppliers a:hover {
  background-color: #14FF00;
}

.about-us {
  display: grid;
  grid-template-columns: 45% 1fr;
  padding-top: 66px;
  margin-top: 90px;
  gap: 50px;
  padding-bottom: 50px;
}

.about-us p:last-of-type {
  padding-bottom: 30px;
}

.about-us-wrapper {
  background-color: #fef9f1;
}

.about-us-img {
  position: relative;
}

.about-us-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(../image/about-us.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-cta {
  padding: 15px 30px;
  background-color: #267DFF;
  border-radius: 50px;
  color: #fff;
  margin-top: 30px;


font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
text-align: center;
cursor: pointer;
color: #FFFFFF;
position: relative;
}


.background-wrapper {
  margin-top: -200px;
}

.swiper-slide {
  width: auto;
  height: auto;
}

.swiper-pagination, .crypto-wallet .swiper-pagination .swiper-pagination-bullet:last-child{
  display: none;
}

.buttonWrapper {
  display: flex;
    gap: 15px;
}

.main-table h2 {
  text-align: center;
}

.mobile-difficulty {
  display: none;
}




@media only screen and (min-width: 769px) {
  .wallet {
    margin-right: auto !important;
  }
  .types-mining .swiper-wrapper {
    flex-direction: column;
    pointer-events: none;
  }

  .read-more-2,
  .read-more-3 {
    display: none !important;
  }
  
  .hardware-wallets .m-slide-down,
  .how-to-content .m-slide-down {
    height: auto !important;
  }

  
}

.read-more-2,
.read-more-3 {
  display: block;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
color: #000000;
width: 100px;
border-bottom: 3px solid #14FF00;
position: relative;
}

.read-more-2::after,
.read-more-3::after{
  position: absolute;
  content: "";
  width: 15px;
  height: 18px;
  background-image: url(../image/arrow-down-black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  bottom: 7px;
}

.read-more-2.close,
.read-more-3.close {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .logo {
    width: 137px;
  }

  .mobile-wrapper {
    padding-left: 13px;
  }

  .border-left p {
    min-width: 111px;
  }

  .mobile-wrapper p {
    padding-left: 10px;
  }

  .border-left p::before {
    left: 10px;
  }
  .m-cap-desktop {
    width: 166px;
  }
  .difficulty p::before {
    left: 10px;
  }

  .difficulty {
    width: 154px;
  }

  .m-cap-desktop p::before {
    left: 10px;
  }

  .m-cap-desktop,
  .difficulty {
    margin-right: 5px;
  }

  .cta a {
    width: 126px;
  }


  .wallet {
    grid-template-columns: 1fr;
  }

  .what-is {
    grid-template-columns: 45% 1fr;
    gap: 10px;
  }
  .what-is p {
    padding-top: 20px;
  }

  .trusted-grid {
    width: 100%;
  }

  .site-header .container::before {
    left: -188px;
    z-index: -1;
  }

  .site-header-inner::after {
    right: 125px
  }

}


@media only screen and (max-width: 992px) {
  .how-to-img::before {
    width: 460px;
    height: 658px;
  }
  h2 {
    line-height: 55px;
  }

  .what-is {
    grid-template-columns: 1fr;
    gap: 10px;
    padding-top: 50px;
  }
  .grid-col-2 {
    padding-top: 25px;
  }
  h2 {
    margin-bottom: 10px;
  }
  .what-is::before {
    bottom: -50px;
  }
  .header-description::before {
    right: -100px;
  }
  .site-header-img::before {
    left: 0;
  }
  .site-header-inner::after {
    right: 35px;
  } 
  .tab-button {
    padding: 13px 19px;
  }

  .grid-box {
    width: 100%;
  }

  .trusted-grid {
    gap: 10px
  }

  .m-cap-desktop,
  .difficulty {
    display: none;
  }

  .main-table .td {
    flex-direction: column;
    gap: 0px;
  }

  .cta {
    padding-top: 15px;
  }

  .intro {
    width: 100%;
  }
  
  .mobile-wrapper-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .logo,
  .mcap-mobile {
    width: 49%;
  }

  .mcap-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
  }

  .mcap-mobile p::before {
    content: "Market Cap";
  }

  .cta a {
    width: 201px;
  }

  .mobile-difficulty {
    display: inline;
    background-color: #f5f5f5;
  }

  .mobile-difficulty p {
    border-left: 0px;
  }

  .mobile-difficulty p::before {
    content: "Difficulty";
  }
  
  .inner-content-table {
    width: 100%;
  }

  .border-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .mobile-wrapper {
    display: initial
  }

  .mcap-mobile {
    display: block;
    padding-left: 20px;
    padding-top: 50px;
}

  .mcap-mobile p {
    font-weight: 800;
    font-size: 16px;
  }

  .mcap-mobile p::before {
    top: -25px;
    left: 0;
  }

  .t-expand-body {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;
  }

  .t-expand-title p {
    padding: 10px;
    border: 1.5px solid #000;
  }

  .t-expand-title * {
    pointer-events: none;
  }

  .t-expand-title {
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .t-expand-title::before {
    position: absolute;
    content: "";
    background-color: #000;
    width: 14px;
    height: 2px;
    right: 16px;
    top: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  
  }

  .t-expand-title::after {
    position: absolute;
    content: "";
    background-color: #000;
    width: 2px;
    height: 14px;
    right: 22px;
    top: 18px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
  }

  .t-expand.expanded .t-expand-title::after {
    transform: rotate(90deg);
  }

  .t-expand.expanded .t-expand-body {
    height: auto;
    overflow: visible;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 10px;
  }
  
}

.paper-wallet {
  display: none;
}


@media only screen and (max-width: 768px) {

  h1 {
    padding-top: 25px;
    padding-bottom: 0;
    font-size: 42px;
  }

  h2 {
    line-height: 40px;
    font-size: 36px !important;
  }

  .site-header-inner {
    display: flex;
  }

  .site-header-content {
    width: 100%;
  }

  .container {
    padding: 0 22px;
  }

  .logo-wrapper {
    padding-top: 22px;
  }

  .first-col, .second-col, .nav-bar {
    gap: 12px;
  }

  nav {
    padding-top: 30px;
  }

  .site-header-img::before {
    left: -100px;
  }
  .site-header-inner::after {
    right: -60px;
    top: 331px;
  }

  .header-description::before {
    z-index: -1;
    right: -10px;
    top: -65px;
  }

  nav a::after {
    opacity: 1;
    transform: translate(0, 0);
  }

  .site-header .container::after {
    position: absolute;
    content: "";
    width: 174px;
    height: 174px;
    background-color: #267DFF;
    border-radius: 50%;
    top: 76px;
    right: -95px;
    z-index: -6;
  }

  .site-header-img::after {
    content: none;
  }

  .grid-col-2 {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .what-is::before {
    content: none;
  }

  .what-is-mobile-img {
    width: 110%;
    height: 90px;
    background-image: url(../image/currencies.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 10px;
  }

  .proof-of-work {
    margin-top: 20px;
    padding-top: 40px;
  }

  .proof-of-work .container {
    padding: 0;
  }

  .crypto-wallet,
  .types-mining {
    padding: 40px;
  }

  .hardware-wallets {
    padding: 50px 40px 0;
  }

  .hardware-wallets p {
    padding-bottom: 20px !important;
  }


  .setups {
    flex-direction: column;
  }

  .how-to {
    grid-template-columns: 1fr;
    padding-top: 40px;
    gap: 0;
  }

  .how-to-img::before {
    content: none;
  }

  .how-to-content h2 {
    padding-bottom: 20px;
    line-height: 40px;
  }

  .types-box {
    height: 300px;
  }

  footer .container {
    flex-direction: column;
    padding-bottom: 24px;
  }

  .how-to-content .m-slide-down p:last-of-type {
    padding-bottom: 0px;
  }

  .main-table {
    padding-top: 40px;
  }

  

  .m-cap-desktop {
    display: none;
  }

  .nav-bar {
    flex-direction: column;
  }

  .mining-wrapper h2::before {
    content: none;
  }

  .mining-wrapper {
    gap: 0px;
  }

  .mining-wrapper h2 {
    padding-bottom: 10px;
    text-align: center;
  }

  .mining-wrapper h2 span {
    display: initial;
  }

  .mining-img-inner {
    height: 145px;
  }

  /* SWIPER  */

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 80%;
  }

  .swiper-slide,
  .swiper {
    overflow: visible !important;
    background-color: #fff;
  }

  .grid-wallets {
    display: flex;
    gap: 0;
  }

  .wallet {
    height: 170px;
  }

  .paperwallet {
    display: none
  }

  .paper-wallet {
    display: inline;
  }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -29px;
    left: 0;
    width: auto;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
    border-radius: 0;
    background-color: black;
    transition: all 0.2 ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 40px;
  }

  .swiper-pagination-bullet {
    transition: all 0.3s ease-in-out;
  }

  .background-wrapper {
    border: 1.5px solid #000;
  }

  .swiper-wrapper {
    position: relative;
  }

  .swiper-pagination {
    display: block;
  }

  .swiper-wrapper::before {
    width: 550%;
    height: 100%;
    position: absolute;
    content: "";
    background-color: #fff;
    top: 25px;
    left: -30px;
    z-index: -1;
  }
  .types-mining {
    padding-bottom: 60px;
  }

  .types-mining .swiper-wrapper::before {
    top: 0px;
    height: 110%;
    width: 300%;
  }
  .how-to-content p:last-of-type {
    padding-bottom: 0;
  }

  .how-to-content p:first-of-type {
    padding-bottom: 20px;
  }

  .steps {
    grid-template-columns: 1fr;
    gap: 0;
    padding-top: 30px;
  } 

  .steps h3 {
    padding-left: 35px;
  }

  .step::before {
    top: -14px;
  }

  .navigation {
    flex-direction: column;
    gap: 15px;
  }

  .navigation p {
    text-align: center;
  }

  .top-three-categories::after {
    content: none;
  }

  .trusted-grid {
    grid-template-columns: 1fr;
  }

  .grid-box {
    width: 100%;
  }

  .about-us {
    display: flex;
    gap: 0;
  }

  .about-us-mobile-img {
    width: 420px;
    height: 250px;
    position: relative;
    margin-bottom: 25px;
  }

  .about-us-content {
    width: 100%;
  }

  .about-us-mobile-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/about-us.svg);
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    left: -55px;
  }

  

}




@media only screen and (max-width: 480px) {
  .types-box {
    height: 400px;
    padding: 24px;
  }

  .mining-img-inner {
    height: 118px;
  }

  .tab-button {
    padding: 13px 11px !important;
  }

  .box {
    padding: 28px;
  }
}


@media only screen and (max-width: 385px) {
  .wallet {
    height: 200px;
  }
  .cta-wallets a {
    width: 155px;
  }
  .crypto-wallet, .types-mining {
    padding: 20px;
  }

  .mcap-mobile {
    padding-left: 7px;
  }

  .logo {
    width: 44%;
  }

  .mcap-mobile {
    width: 54%;
  }

  .types-mining {
    padding-bottom: 50px;
  }

  .types-mining .swiper-wrapper::before {
    top: 13px;
    height: 100%;
  }


  .box {
    padding: 24px;
  }
  
  .setups a {
    padding: 15px;
  }

  .container {
    padding: 0 12px;
  }
  .hardware-wallets {
    padding: 50px 22px 0;
  }

  .tab-button {
  padding: 13px 5px;
  }

  .buttonWrapper {
    gap: 5px;
  }

  .box {
    margin-top: 20px;
  }

  .specs-wrapper li {
    margin-top: 13px;
  }
  .mining-img-inner {
    height: 105px;
  }
}

@media only screen and (max-width: 339px) {
  .types-box {
    height: 455px;
  }

  
}

@media only screen and (max-width: 351px) {
  .tab-button {
    font-size: 14px;
    padding: 10px 8px !important;
  }
  
  .box {
    padding: 15px;
  }
}

@media only screen and (min-width: 993px) {
  .t-expand-title {
    display: none;
  }
}


